@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Mulish" !important;
    font-size: 14px !important;
}

img, table, td, blockquote, code, pre, textarea, input, iframe, object, embed, video {
    max-width: 100%;
}

h2 {
    text-align: center;
}
.title {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
}

.marginPages {
  margin: 35px 8%;
}

.marginTop_5 {
  margin-top: 5%;
}

.margin_2pourcent {
  margin: 2%;
}

.margin_1pourcent {
  margin: 1%;
}

#cgu .tableContents ol, #cgu .data ol { counter-reset: item }
#cgu .tableContents li, #cgu .data ol li.title { display: block }
#cgu .tableContents li:before, #cgu .data ol li.title:before { content: counters(item, ".") ". "; counter-increment: item }


#cookies table {
  border: 1px solid;
}

#diabete h4 {
  font-weight: bold;
}

#diabete h3 {
  color: black;
  text-align: left;
  padding-left: 12px;
  font-weight: bold;
}

#diabete ul{
  list-style-type: none;
  padding-left: 0;
}



.maj {
  font-style: italic;
}

h3 {
  font-size: 36px;
  color: #283d50;
  text-align: center;
  font-weight: 500;
  position: relative;
}

.bold {
  font-weight: bold;
}

.width_55 {
  width: 55%;
}

.width_60 {
  width: 60%;
}

.width_80 {
  width: 80%;
}

.textAlign_center {
  text-align: center;
}

.fontSize_12 {
  font-size: 12px;
}

.opacity {
  opacity: 0.2;
}

.color_warm_grey {
  color: #9c9393;
}

.btn-loginConnection {
  background-color: #8fc740;
  color: white;
  border-radius: 30px;
}

.btn-linkGray, .color-gray {
  color: #6c757d;
}

.btn-linkGrayUnderline {
  color: #6c757d;
  text-decoration: underline;
}

.btn-outline-back {
  color: #8fc740;
  border-color: #8fc740;
  border-radius: 30px;
}

/* Menu */
.btn-peopleButton {
  background-color: white;
  color: black;
  width: 100%;
}

.btn-peopleButton::after {
  margin-left: 10px;
}

.head-text {
  position: relative;
}

/* Logo */
img.logo-menu-top {
  width: 99.3px;
  height: 59px;
  object-fit: contain;
}

img.logo-footer {
  width: 70px;
  height: 42px;
}

/* Footer */
.footer-links {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.footer-register {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

/* Dashboard */
/* img.img_dashbord {
  width: auto;
  max-height: 60%;
  object-fit: contain;
} */

.btn-next-planning-calendar {
  color: white;
  background-color: #8fc740;
}

.Planification-de-vos-menu {
  margin: 0 1%;
  color: white;
  position: absolute;
  top: 30%;
  bottom: 0;
  left: 0;
  right: 0;

  height: 40px;
  font-size: 32px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.Organisez-vos-semaines {
  margin: 0 1%;
  color: white;
  position: absolute;
  top: 38%;
  bottom: 0;
  left: 0;
  right: 0;

  height: 60px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.Besoin-daide {
  color: white;
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  height: 21px;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

/* Planning */

.planning-structure {
  cursor: pointer;
}

.planning-structure a:hover,
.planning-structure .active {
  color: #8fc740;
}

/* Menu */

.Nom-du-plat {
  color: white;
  position: absolute;
  top: 30%;
  bottom: 0;
  left: 0;
  right: 0;

  width: 83px;
  height: 18px;
  font-family: "Mulish";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--style);
}

.avatar_active {
  border-bottom: 4px solid green !important ;
}

.avatar_inactive {
  border-bottom: 4px solid #fc6e51 !important;
}

.nice-dates-day:before {
  background-color: #fc6e51;
  z-index: 1;
}

.nice-dates-day:after {
  border: 2px solid #fc6e51;
  transform: scale(0.95);
  transition-duration: 0.15s;
  transition-property: transform, opacity;
  z-index: 2;
}

.nice-dates-day.-today {
  font-weight: 600;
  color: #fc6e51;
  font-size: xx-large;
  /* border: 2px solid #fc6e51;
  border-radius: 999px; */
}

.CardImgOverlayTitle {
  height: 122px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.9)
  );
  padding: 5px 5px 5px 5px;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  margin-top: -120px;
  margin-bottom: 0px;
  display: flex;
}

.allergen_label {
  position: relative;
  text-align: center;
  font-size: 12px;
}

.allergen_circle {
  width: 90px;
  height: 90px;
  position: relative;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.allergen_circle_blocked {
  border: 3px solid #fc6e51;
}

.blocked {
  content: "";
  position: absolute;
  width: 85px;
  height: 3px;
  background-color: #fc6e51;
  border-radius: 0;
  top: 40px;
  transform: rotate(-50deg);
  /* left: 6px; */
  z-index: 0;
}

.guestGroupButtonActive {
  width: 50%;
  height: 45px;
  border-radius: 1px;
  background-color: #fd694d;
  color: white;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guestGroupButton {
  width: 50%;
  height: 45px;
  border-radius: 1px;
  border: solid 1px #e3e1e1;
  color: #9c9393;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-cols-md-8 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 12.5%;
}

.shopping_preference_title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 3%;
  text-align: center;
}

.shopping_preference {
  min-width: 200px;
  max-width: 240px;
  height: 50px;
  margin: 0px 25px;
  padding: 11px 25px;
  border-radius: 2px;
  box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1);
}

.shopping_card_kits {
  width: 200px;
  height: 200px;
  margin: 25px 25px;
  padding: 23px 5px;
  border-radius: 16px;
  box-shadow: 10px 10px 30px 0 rgba(174, 174, 192, 0.4);
}

.shopping_card_photo {
  width: 88px;
  height: 88px;
  margin: 14px 50px 14px 49.9px;
  border-radius: 8px;
  box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1);
}

.dot_green {
  width: 570px;
  height: 570px;
  background-color: #8fc740;
  opacity: 0.2;
  display: inline-block;
  position: absolute;
  top: calc(var(--windowHeight)); /*25%;*/
  left: 30%;
  z-index: -1;
  clip-path: circle(50% at 50% 80%);
}

.dot_orange {
  width: 348px;
  height: 348px;
  background-color: #f2600d;
  border-radius: 50%;
  opacity: 0.2;
  display: inline-block;
  position: absolute;
  top: 15%;
  left: 65%;
  z-index: -1;
}

.loader {
  position: absolute;
  top: calc(50%);
  left: calc(50%);
}

/* Phone */
@media screen and (max-width: 768px) {
  .Planification-de-vos-menu {
    margin: 0 3%;
    color: white;
    position: absolute;
    top: 15%;
    bottom: 0;
    left: 0;
    right: 0;
  
    height: 40px;
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .Organisez-vos-semaines {
    margin: 0 3%;
    color: white;
    position: absolute;
    top: 38%;
    bottom: 0;
    left: 0;
    right: 0;
  
    height: 60px;
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .Besoin-daide {
    color: white;
    position: absolute;
    bottom: 15%;
    left: 0;
    right: 0;
    height: 21px;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }
}

/* Tablet */
@media screen and (max-width: 992px) {
}

/* .loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

@media print {
  .menuItems, .customButton, .shopping_addCardBox, .footer {
    display: none !important;
  }

  .width_55, .width_60, .width_80  {
    width: 100% !important;
  }

  @page {
    margin: 0.5cm !important;
  }
}